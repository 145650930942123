import React, { forwardRef, Fragment } from 'react'
import Link from 'next/link'

import { Avatar, AvatarProps } from 'antd'

import { useUserStore } from '@/stores/user.store'

import { ROUTES } from '@/constants/routes'

import './index.scss'

export type Ref = HTMLElement

const UserAvatar = forwardRef<Ref, AvatarProps>(
  ({ size = 50, className = '', ...rest }, ref) => {
    const userProfile = useUserStore((state) => state.userProfile)

    if (!userProfile) return <Fragment />

    return (
      <Link href={{ pathname: ROUTES.PROFILE.INDEX }}>
        <Avatar
          alt="User Avatar"
          ref={ref}
          className={`user-avatar ${className}`}
          src={userProfile.avatar}
          size={size}
          {...rest}
        />
      </Link>
    )
  },
)

UserAvatar.displayName = 'UserAvatar'

export default UserAvatar
